var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "circum-customer",
    },
    [
      _c("div", { staticClass: "top-left" }, [
        _c("div", { staticClass: "top-item1" }, [
          _c("div", { staticClass: "block bg1" }, [
            _c("div", { staticClass: "block-msg" }, [
              _c("span", { staticClass: "txt1" }, [_vm._v("总人口数")]),
              _c("span", { staticClass: "txt2 color1" }, [
                _vm._v(_vm._s(_vm.totalNum)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "block bg2" }, [
            _c("div", { staticClass: "block-msg" }, [
              _c("span", { staticClass: "txt1" }, [_vm._v("总家庭户数")]),
              _c("span", { staticClass: "txt2 color2" }, [
                _vm._v(_vm._s(_vm.totalFamily)),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "top-item2" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("性别分布")]),
            _vm._l(_vm.sexFenBu, function (item, index) {
              return _c(
                "div",
                { key: index + item.name, staticClass: "bar-item" },
                [
                  _c("span", { staticClass: "bar-title" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "bar",
                      class: {
                        "bar-bg-color1": index === 0,
                        "bar-bg-color2": index === 1,
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "bar-pro",
                        class: {
                          "bar-pro-color1": index === 0,
                          "bar-pro-color2": index === 1,
                        },
                        style: { left: (395 / 100) * item.value - 395 + "px" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "rate",
                      class: {
                        "bar-rate-color1": index === 0,
                        "bar-rate-color2": index === 1,
                      },
                    },
                    [_vm._v(" " + _vm._s(item.value) + "% ")]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "bottom-left" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("学历分布")]),
          _c("pieChart", { attrs: { echartData: _vm.xueLiFenBu.preData } }),
          _c("div", { staticClass: "wrap", attrs: { id: "age" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-right" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("家庭户数代数分布")]),
          _vm._l(_vm.familyFenBu, function (item, index) {
            return _c(
              "div",
              { key: index + item.name, staticClass: "bar-item" },
              [
                _c("span", { staticClass: "bar-title" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "bar bar-bg-color3" }, [
                  _c("div", {
                    staticClass: "bar-pro bar-pro-color3",
                    style: { left: (380 / 100) * item.value - 380 + "px" },
                  }),
                ]),
                _c("div", { staticClass: "rate bar-rate-color3" }, [
                  _vm._v(_vm._s(item.value) + "%"),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-right" }, [
      _c("div", { staticClass: "title" }, [_vm._v("年龄分布（岁）")]),
      _c("div", { staticClass: "wrap", attrs: { id: "line" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }