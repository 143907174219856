//周边客群分析
<template>
  <div class="circum-customer" v-loading="loading">
    <div class="top-left">
      <div class="top-item1">
        <div class="block bg1">
          <div class="block-msg">
            <span class="txt1">总人口数</span>
            <span class="txt2 color1">{{ totalNum }}</span>
          </div>
        </div>
        <div class="block bg2">
          <div class="block-msg">
            <span class="txt1">总家庭户数</span>
            <span class="txt2 color2">{{ totalFamily }}</span>
          </div>
        </div>
      </div>
      <div class="top-item2">
        <div class="title">性别分布</div>
        <div
          class="bar-item"
          v-for="(item, index) in sexFenBu"
          :key="index + item.name"
        >
          <span class="bar-title">{{ item.name }}</span>
          <div
            class="bar"
            :class="{
              'bar-bg-color1': index === 0,
              'bar-bg-color2': index === 1,
            }"
          >
            <div
              class="bar-pro"
              :class="{
                'bar-pro-color1': index === 0,
                'bar-pro-color2': index === 1,
              }"
              :style="{ left: (395 / 100) * item.value - 395 + 'px' }"
            ></div>
          </div>
          <div
            class="rate"
            :class="{
              'bar-rate-color1': index === 0,
              'bar-rate-color2': index === 1,
            }"
          >
            {{ item.value }}%
          </div>
        </div>
      </div>
    </div>

    <div class="top-right">
      <div class="title">年龄分布（岁）</div>
      <div class="wrap" id="line"></div>
    </div>

    <div class="bottom-left">
      <div class="title">学历分布</div>
      <pieChart :echartData="xueLiFenBu.preData"></pieChart>
      <div class="wrap" id="age"></div>
    </div>

    <div class="bottom-right">
      <div class="title">家庭户数代数分布</div>
      <div
        class="bar-item"
        v-for="(item, index) in familyFenBu"
        :key="index + item.name"
      >
        <span class="bar-title">{{ item.name }}</span>
        <div class="bar bar-bg-color3">
          <div
            class="bar-pro bar-pro-color3"
            :style="{ left: (380 / 100) * item.value - 380 + 'px' }"
          ></div>
        </div>
        <div class="rate bar-rate-color3">{{ item.value }}%</div>
      </div>
    </div>
  </div>
</template>
<script>
import PieChart from "../../components/chart/pieChart.vue";
import { GetCommonInfo } from "@/service";
// import * as echarts from "echarts";
import { line1 } from "@/assets/javascript/echars/lineChart.js";
import { wkb2geom, geom2wkt } from "@/assets/javascript/map/map.utils.js";
import { numFormat } from "@/assets/javascript/utils.js";
import * as turf from "@turf/turf";
// import { pre1 } from "@/assets/javascript/echars/preChart.js";

export default {
  name: "circumCustomer",
  props: {
    id: {
      type: String,
      required: true,
      default: "1002094050",
    },
    identify: {
      type: String,
      required: true,
      default: "商圈",
    },
    posInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    id: {
      async handler(val, oldval) {
        if (val && val !== oldval) {
          switch (this.identify){
            case "商圈":
            case "商场":
            case "单品牌":
              this.loading = true;
              this.drawMarkerPolygon();
              this.loading = false;
              break
            case "品牌":
              this.loading = true;
              await this.getBrandCustomerGroup();
              this.loading = false;
              break
          }
          // if (this.identify === "商圈") {
          //   console.log('watchhandler', this.posInfo);
          //   // this.loading = true;
          //   // await this.getBusinessFenBu();
          //   // this.loading = false;
          // } else if (this.identify === "商场") {
          //   // this.loading = true;
          //   // await this.getShopAgeFenBu();
          //   // await this.getShopOtherFenBu();
          //   // this.loading = false;
          // } else if (this.identify === "品牌") {
          //
          // }
          // this.getAgeFenBu();
          // this.getSexFenBu();
          // this.getXueLiFenBu();
          // this.getFamilyNumberFenBu();
          // this.getTotalPeople();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      totalNum: 0,
      totalFamily: 0,
      businessFields: [
        "总人口数",
        "总家庭户数",
        "男性数量",
        "女性数量",
        "教育-小学",
        "教育-初中",
        "教育-高中",
        "教育-专科",
        "教育-大学及以上",
        "家庭代数-1代",
        "家庭代数-2代",
        "家庭代数-3代",
        "家庭代数-4代及以上",
        "0-10岁",
        "10-20岁",
        "20-30岁",
        "30-40岁",
        "40-50岁",
        "50-60岁",
        "60岁以上",
      ],
      shopAgeFields: [
        "0-10岁",
        "10-20岁",
        "20-30岁",
        "30-40岁",
        "40-50岁",
        "50-60岁",
        "60岁以上",
      ],
      shopOtherFields: [
        "总人口数",
        "总家庭户数",
        "男性数量",
        "女性数量",
        "教育-大学及以上",
        "教育-初中",
        "教育-高中",
        "教育-专科",
        "教育-小学",
        "家庭代数-1代",
        "家庭代数-2代",
        "家庭代数-3代",
        "家庭代数-4代及以上",
      ],
      familyFenBu: [
        {
          name: "一代户",
          value: 0,
        },
        {
          name: "二代户",
          value: 0,
        },
        {
          name: "三代户",
          value: 0,
        },
        {
          name: "四代及以上",
          value: 0,
        },
      ],
      sexFenBu: [
        {
          name: "男性分布",
          value: 0,
        },
        {
          name: "女性分布",
          value: 0,
        },
      ],
      xueLiFenBu: {
        richColor: {},
        preData: [],
      },
      ageFenBu: {
        max: 100,
        data: [],
      },
      loading: false,
      polygon: "",
    };
  },
  methods: {
    // 画一个500米的圆
    async drawMarkerPolygon() {
      let { identify, posInfo } = this;
      let radius = identify === "商场" ? 3000 : 500;
      let polygon
      if (identify !== "商圈") {
        if (identify === "商场") {
          polygon = posInfo.geom
        } else {
          // 单品牌
          polygon = wkb2geom(posInfo.geom);
        }
        let center = polygon.coordinates;

        let circle = turf.circle(center, radius / 1000, {
          steps: 256,
          units: "kilometers",
        });
        this.polygon = geom2wkt(circle.geometry);
      } else {
        this.polygon = geom2wkt(posInfo.geom)
      }
      this.getCardList();
      this.getGenderList();
      this.getAgeList();
      this.getEducationList();
      this.getFamilyList();
    },
    numFormat,
    // 获取单品牌门店-客群特征
    async getCardList() {
      let params = {
        identify: "sg-data-位置-客群特征",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["总人口数", "总家庭户数"],
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.totalNum = numFormat(details[0] || 0);
        this.totalFamily = numFormat(details[1] || 0);
        let obj = {
          totalNum:this.totalNum,
          totalFamily:this.totalFamily
        };
         this.$emit("getTotal",obj)
      }
    },
    // 获取单品牌门店-性别分布
    async getGenderList() {
      let params = {
        identify: "sg-data-位置-性别分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["男", "女"],
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.initSexData(details);
      }
    },
    // 获取单品牌门店-年龄分布
    async getAgeList() {
      let params = {
        identify: "sg-data-位置-年龄分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [
          "0-10岁",
          "10-20岁",
          "20-30岁",
          "30-40岁",
          "40-50岁",
          "50-60岁",
          "60岁以上",
        ],
      };
      let res = await GetCommonInfo(params);
      // console.log("年龄分布", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.initAgeData(details);
      }
    },
    // 获取单品牌门店-年龄分布
    async getEducationList() {
      let params = {
        identify: "sg-data-位置-教育结构",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [
          "小学教育程度",
          "初中教育程度",
          "高中教育程度",
          "大学专科教育程度",
          "大学本科教育程度",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.initXueLiData(details);
      }
    },
    // 获取单品牌门店-家庭户数代数分布
    async getFamilyList() {
      let params = {
        identify: "sg-data-位置-家庭户数代数分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["一代户", "二代户", "三代户", "四代及以上"],
      };
      let res = await GetCommonInfo(params);
      // console.log("家庭户数代数分布", res);
      if (res && res.code === 200) {
        let details =
          res.data.items && res.data.items.length ? res.data.items[0] : [];
        details.forEach((item, index) => {
          this.familyFenBu[index].value = ~~(item * 100);
        });
      }
    },
    // 获取聚合品牌数据
    async getBrandCustomerGroup() {
      let identify = "sg-data-品牌-客群特征";
      let params = {
        identify,
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.id],
          },
        ],
        fields: this.businessFields,
      };
      const res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let items = (res.data.items && res.data.items[0]) || [];
        this.initBusinessData(items);
      }
    },
    //获取年龄分布数据
    async getShopAgeFenBu() {
      let identify = "sg-data-商场-周边客群分析-年龄分布";
      const params = {
        identify,
        filters: [
          {
            field: "商场唯一code",
            cond: 0,
            value: [this.id],
          },
        ],
        fields: this.shopAgeFields,
      };
      const res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let items = (res.data.items && res.data.items[0]) || [];
        this.initAgeData(items);
      }
    },
    async getShopOtherFenBu() {
      let identify = "sg-data-商场-周边客群分析-其他";
      const params = {
        identify,
        filters: [
          {
            field: "商场唯一code",
            cond: 0,
            value: [this.id],
          },
        ],
        fields: this.shopOtherFields,
      };
      const res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let items = (res.data.items && res.data.items[0]) || [];
        this.initShopOtherData(items);
      }
    },
    //获取商圈数据
    async getBusinessFenBu() {
      let identify = "sg-data-商圈-客群特征";
      let params = {
        identify,
        filters: [
          {
            field: "key1",
            cond: 0,
            value: [this.id],
          },
        ],
        fields: this.businessFields,
      };
      const res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let items = (res.data.items && res.data.items[0]) || [];
        this.initBusinessData(items);
      }
    },
    //初始化商圈数据
    initBusinessData(data) {
      let businessData = [];
      this.businessFields.forEach((v, i) => {
        let tempData = data[i] || 0;
        businessData.push(tempData);
      });
      this.initPeople(businessData.slice(0, 2));
      this.initSexData(businessData.slice(2, 4));
      this.initXueLiData(businessData.slice(4, 9));
      this.initFamilyData(businessData.slice(9, 13));
      this.initAgeData(businessData.slice(13, 20));
    },
    //初始化总人口数
    initPeople(data) {
      this.totalFamily = numFormat(data[1] || 0);
      this.totalNum = numFormat(data[0] || 0);
      let obj = {
        totalNum:this.totalNum,
        totalFamily:this.totalFamily
      };
      this.$emit("getTotal",obj)
    },
    //初始化家庭人数数
    initFamilyData(data) {
      let total = 0;
      data.forEach((v) => {
        total += v;
      });
      total = !total ? 1 : total;
      data.forEach((v, k) => {
        let tempNum = ((v / total) * 100).toFixed(0);
        this.familyFenBu[k].value = tempNum;
      });
    },
    //初始化学历数据
    initXueLiData(data) {
      // const preColor = ["#00BB87", "#FF6161", "#897CFD", "#2DBAE9", "#3519FB"];
      // const preTxt = ["本科及以上", "初中", "高中", "专科", "小学"];
      const preColor = ["#3519FB","#FF6161", "#897CFD", "#2DBAE9", "#00BB87"];
      const preTxt = ["小学", "初中", "高中", "专科", "本科及以上"];
      let preData = [];
      let richColor = {};
      let total = 0;
      data.forEach((v) => {
        total += Number(v);
      });
      total = !total ? 1 : total;
      preColor.forEach((v, k) => {
        // let value = ((data[k] / total) * 100).toFixed(0);
        preData.push({
          value: data[k],
          name: preTxt[k],
          itemStyle: {
            color: v,
          },
        });
        richColor[data[k]] = {
          color: v,
        };
      });

      this.xueLiFenBu.richColor = richColor;
      this.xueLiFenBu.preData = preData;

      // this.$nextTick().then(() => {
      //   this.createPreEchars();
      // });
    },
    //初始化性别数
    initSexData(data) {
      let total = 0;
      data.forEach((v) => {
        total += v;
      });
      total = !total ? 1 : total;
      data.forEach((v, k) => {
        let tempNum = ((v / total) * 100).toFixed(0);
        this.sexFenBu[k].value = tempNum;
      });
    },
    //初始化年龄数据
    initAgeData(data) {
      let max = 0;
      let ageData = [];
      this.shopAgeFields.forEach((v, i) => {
        let tempData = data[i] || 0;
        let temp = (tempData * 100).toFixed(0) - 0;
        if (temp > max) {
          max = temp;
        }
        ageData.push(temp);
      });

      this.ageFenBu.max = max;
      this.ageFenBu.data = ageData;

      this.$nextTick().then(() => {
        this.createLineEchars();
      });
    },
    //初始化 商场other数据
    initShopOtherData(data) {
      let otherData = [];
      this.shopOtherFields.forEach((v, i) => {
        let tempData = data[i] || 0;
        otherData.push(tempData);
      });
      this.initPeople(otherData.slice(0, 2));
      this.initSexData(otherData.slice(2, 4));
      this.initXueLiData(otherData.slice(4, 9));
      this.initFamilyData(otherData.slice(9, 13));
    },
    //创建年龄折线图
    createLineEchars() {
      const chartDom = document.getElementById("line");
      const xData = [
        "0-10",
        "10-20",
        "20-30",
        "30-40",
        "40-50",
        "50-60",
        "60以上",
      ];
      const chart = line1(xData, this.ageFenBu.data, this.ageFenBu.max);
      const myChart = chart[1].init(chartDom);
      myChart.setOption(chart[0]);
    },
    //创建学历饼状图
    // createPreEchars() {
    //   const chartDom = document.getElementById("age");
    //   const chart = pre1(this.xueLiFenBu.preData, this.xueLiFenBu.richColor);
    //   const myChart = chart[1].init(chartDom);
    //   myChart.setOption(chart[0]);
    // },
  },
  created() {},
  mounted() {},
  components: { PieChart },
};
</script>
<style lang="less" scoped>
.circum-customer {
  position: relative;
  height: 585px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 1px);
    top: 0;
    width: 1px;
    height: 584px;
    background: #eeeeee;
  }
  &&::after {
    content: "";
    position: absolute;
    position: absolute;
    left: 0;
    top: 304px;
    width: 100%;
    height: 1px;
    background: #eeeeee;
  }
  //小标题
  .title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;
    margin-bottom: 28px;
  }
  //总人口数 及家庭户数
  .block {
    width: 242px;
    height: 68px;
    .block-icon {
    }
    .block-msg {
      height: 68px;
      margin-left: 70px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .txt1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #000;
      }
      .txt2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }

  //进度条
  .bar-item {
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 28px;
    &:last-of-type {
      margin-bottom: 0px;
    }
    .bar-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 20px;
    }
    .bar {
      width: 395px;
      height: 10px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      margin: 0 11px 0 8px;
      border-radius: 2px;
      .bar-pro {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 398px;
        height: 10px;
        box-sizing: content-box;
      }
    }
    .rate {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .top-left {
    width: 50%;
    height: 305px;
    .top-item1 {
      box-sizing: border-box;
      border-bottom: 1px solid #eeeeee;
      padding: 29px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bg1 {
        background-image: url("../../assets/images/cards/people_bg@2x.png");
        background-position: top top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .bg2 {
        background-image: url("../../assets/images/cards/family_bg@2x.png");
        background-position: top top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .color1 {
        color: #3519fb;
      }
      .color2 {
        color: #00bb87;
      }
    }
    .top-item2 {
      padding: 27px 30px;
      .bar-bg-color1 {
        background: rgba(0, 187, 135, 0.06);
        border-radius: 2px;
        box-shadow: #00bc88 0px 0px 2px;
      }
      .bar-pro-color1 {
        background: linear-gradient(270deg, #00dfbc 0%, #00bb87 100%);
        border-radius: 2px 102px 102px 2px;
      }
      .bar-rate-color1 {
        color: #00bb87;
      }

      .bar-bg-color2 {
        background: rgba(249, 144, 0, 0.08);
        border-radius: 2px;
        box-shadow: rgba(255, 181, 80, 1) 0px 0px 2px;
      }
      .bar-pro-color2 {
        background: linear-gradient(270deg, #ffb754 0%, #f99000 100%);
        border-radius: 2px 100px 100px 2px;
      }
      .bar-rate-color2 {
        color: #f99000;
      }
    }
  }
  .top-right {
    width: 50%;
    height: 305px;
    padding: 27px 30px;
    :deep(.tooltiop-line) {
      background-color: #f99000 !important;
      border: none !important;
      width: auto !important;
      border-radius: 18px !important;
      font-weight: 500 !important;
      color: #ffffff !important;
      padding: 1px 11px !important;
      box-shadow: none !important;
      line-height: 17px !important;
      display: none;
    }
    .title {
      margin-bottom: 16px;
    }
    .wrap {
      height: calc(100% - 20px);
    }
  }
  .bottom-left {
    height: 280px;
    width: 50%;
    padding: 27px 30px;
    .title {
      margin-bottom: 16px;
    }
    .wrap {
      width: 100%;
      height: calc(100% - 20px);
      box-sizing: border-box;
    }
  }
  .bottom-right {
    height: 280px;
    width: 50%;
    padding: 27px 30px 0 30px;
    .title {
      // margin-bottom: 0;
    }
    .bar-item {
      .bar-title {
        width: 70px;
      }
      .bar {
        margin: 0 8px 0 8px;
        width: 376px;
        .bar-pro {
          width: 380px;
        }
      }
    }

    .bar-bg-color3 {
      background: rgba(0, 0, 0, 0.06);
      box-shadow: rgba(0, 0, 0, 0) 0 0 0;
    }
    .bar-pro-color3 {
      background: #3519fb;
      border-radius: 2px 100px 100px 2px;
    }
    .bar-rate-color3 {
      color: #3519fb;
    }
  }
}
</style>
