import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

/**
 * 折线图 样式 （周边客群分析-年龄分析，）
 * @param {Array} xData x轴数据
 * @param {Array} yData y轴数据
 * @param {Number} max y轴显示最大值
 * @returns {[Object,Object]} [option,echars]
 */
export const line1 = (xData, yData, max) => {
  let option = {
    grid: {
      top: 10,
      left: 20,
      bottom: 0,
      right: 20,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisPointer: {
        type: "line",
        lineStyle: {
          color: "#F99000",
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      data: xData,
    },
    yAxis: {
      type: "value",
      max: max,
      min: 0,
      // minInterval: 36,
      axisLabel: {
        formatter: "{value}%",
      },
      splitLine: {
        lineStyle: {
          color: "#F2F5FF",
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    tooltip: {
      trigger: "axis",
      className: "tooltiop-line",
      axisPointer: {
        type: "line",
        lineStyle: {
          color: "rgba(187, 25, 25, 1)",
        },
      },
      position: function (point) {
        // 固定在顶部
        return [point[0] - 14, "10%"];
      },
      formatter: "{c}%",
    },
    series: [
      {
        data: yData,
        type: "line",
        symbolSize: 8,
        itemStyle: {
          color: "#3519FB", //改变折线点的颜色
          lineStyle: {
            color: "#3519FB", //改变折线颜色
            borderWidth: 2,
          },
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(62,78,255,0.1)" },
            { offset: 1, color: "rgba(62,78,255,0)" },
          ]),
        },
      },
    ],
  };
  return [option, echarts];
};
